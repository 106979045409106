import { Dialog, Transition } from "@headlessui/react";
import { push, ref, set } from "firebase/database";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import QuestionView from "./QuestionView";
import { useAnswerObject, useDatabase, useUserObject } from "../../hooks";

import { PencilIcon } from "@heroicons/react/24/outline";

export default function QuestionPopup(props: {
  data: any;
  questionId?: any;
  children: any;
}) {
  let [isOpen, setIsOpen] = useState(false);
  const [userObject, setuserObject] = useUserObject() as any;
  const database = useDatabase();

  const writeQuestionToDb = (question: any) => {
    let qRef = ref(database, "users/" + userObject.uid + "/questions");
    let id = props.questionId;
    if (!id) {
      id = push(qRef).key;
    }
    const questionRef = ref(
      database,
      "users/" + userObject.uid + "/questions/" + id
    );
    set(questionRef, question)
      .then(() => {
        toast.success("Successfully added to db!");
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error pushing to DB");
      });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div onClick={openModal} className="cursor-pointer hover:underline">
        {props.children}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[1800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title as="h3" className="text-yellow-500">
                      <div className="flex">
                        <span className="text-lg font-bold leading-6">
                          Edit Question
                        </span>
                        <PencilIcon className="mx-1 w-3 h-3 my-auto" />
                      </div>
                    </Dialog.Title>
                    <div className="flex cursor-pointer" onClick={closeModal}>
                      <span className="text-red-500 hover:opacity-75 font-medium">
                        Close window
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col w-full h-full mt-6">
                    <QuestionView
                      writeQuestionToDb={writeQuestionToDb}
                      data={props.data}
                      editMode={true}
                      showSolution={true}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
