import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import GenericButton from "../GenericButton";
import moment from "moment";
import { PencilSquareIcon, CheckBadgeIcon } from "@heroicons/react/20/solid";
import RichTextEditor from "./RichTextEditor/RichTextEditor";
import TextPreview from "./TextPreview/TextPreview";

export default function EditorPopup(props: {
  defautValue: string;
  saveHtmlText: (val: string) => void;
}) {
  let [isOpen, setIsOpen] = useState(false);
  const [problemText, setproblemText] = useState(props.defautValue);

  const formatTimeLocal = (datetimeLocal: string) =>
    moment(datetimeLocal).format();

  const createQuestion = () => {};

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <GenericButton variant="primary" size="sm" onClick={openModal}>
        <div className="flex">
          <div className="flex w-4 me-1">
            <PencilSquareIcon />
          </div>
          Edit
        </div>
      </GenericButton>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[1200px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold leading-6 text-yellow-500"
                  >
                    Edit
                  </Dialog.Title>
                  <div className="flex flex-col">
                    <div className="flex w-full">
                      <div className="flex w-full h-full m-4">
                        <RichTextEditor
                          value={problemText}
                          updateValue={setproblemText}
                        />
                      </div>
                      {/* <div className="flex flex-col w-full h-full">
                        <h4 className="mx-auto font-bold">Preview</h4>
                        <TextPreview htmlContent={problemText} />
                      </div> */}
                    </div>

                    <div className="flex justify-end">
                      <GenericButton
                        variant="primary"
                        size="sm"
                        className="bg-green-600"
                        onClick={() => {
                          props.saveHtmlText(problemText);
                          closeModal();
                        }}
                      >
                        <div className="flex">
                          <div className="flex w-4 me-1">
                            <CheckBadgeIcon />
                          </div>
                          Save
                        </div>
                      </GenericButton>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
