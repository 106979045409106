import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Tab } from "@headlessui/react";
import { useDatabase, useUserObject } from "./hooks";
import { toast } from "react-toastify";
import { get, ref, set } from "firebase/database";
import firebase_app from "./firebase-config";
import { firstLetterCapitalize } from "./util";

const auth = getAuth(firebase_app);

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const LoginTabs = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [userObject, setuserObject] = useUserObject();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const database = useDatabase();

  const writeUserToDb = (user: any) => {
    let uRef = ref(database, "users/" + user.uid + "/data");
    const uData = { uid: user.uid, email: user.email, password: password, role: "STUDENT", name: name, phone: phone,
    class: "Class 12", stream:["IIT", "NEET", "BOARDS", "OLYMPIADS"], school: null, location: null, country: null};
    set(uRef, uData)
      .then(() => {
        setuserObject(uData);
        toast.success("Hi " + firstLetterCapitalize(uData.name) + ". Signup Successful!");
        setIsSubmitDisabled(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try Again. Some error occurred.");
        setIsSubmitDisabled(false);
      });
  };

  const getUserFromDb = (user: any) => {
    let uRef = ref(database, "users/" + user.uid + "/data");
    get(uRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const uData = snapshot.val();
          setuserObject(uData);
          toast.success("Hi " + firstLetterCapitalize(uData.name) + ". Welcome back!");
          setIsSubmitDisabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Please try Again. Some error occurred");
        setIsSubmitDisabled(false);
      });
  };

  return (
    <div className="w-full mt-12 max-w-md sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-white p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-yellow-500",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-yellow-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-yellow-100 shadow"
                  : "text-blue-100 hover:bg-white/[0.12]"
              )
            }
          >
            Login
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-yellow-500",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-yellow-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-yellow-100 shadow"
                  : "text-blue-100 hover:bg-white/[0.12]"
              )
            }
          >
            Signup
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60"
            )}
          >
            <form
              className="space-y-7"
              onSubmit={(e) => {
                e.preventDefault();
                setIsSubmitDisabled(true);
                signInWithEmailAndPassword(auth, email, password)
                  .then((userCredential) => {
                    getUserFromDb(userCredential.user);
                  })
                  .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(firstLetterCapitalize(errorCode)); // ":\n" + errorMessage
                    setIsSubmitDisabled(false);
                  });
              }}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6 focus:outline-none"
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-semibold text-yellow-500 hover:text-yellow-400"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6 focus:outline-none"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400"
                >
                  Sign in
                </button>
              </div>
            </form>
          </Tab.Panel>
          <Tab.Panel
            className={classNames(
              "rounded-xl bg-white p-3",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-yellow-400 focus:outline-none focus:ring-2"
            )}
          >
            <form
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                setIsSubmitDisabled(true);
                createUserWithEmailAndPassword(auth, email, password)
                  .then((userCredential) => {
                    writeUserToDb(userCredential.user);
                  })
                  .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    toast.error(firstLetterCapitalize(errorCode)); // ":\n" + errorMessage
                    setIsSubmitDisabled(false);
                  });
              }}
            >
              <div className="flex flex-row w-full space-x-5">
                <div className="w-1/2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      type="text"
                      autoComplete="name"
                      minLength={2}
                      maxLength={50}
                      pattern="[a-zA-Z ]*"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6 focus:outline-none"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Mobile Number
                  </label>
                  <div className="mt-2">
                    <input
                      id="phone"
                      type="tel"
                      autoComplete="tel"
                      maxLength={10}
                      minLength={10}
                      pattern="[0-9]{10}"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6 focus:outline-none"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6 focus:outline-none"
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Set Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-yellow-500 sm:text-sm sm:leading-6 focus:outline-none"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default LoginTabs;
