import GenericButton from "../components/GenericButton";
import React from "react";

const Instruction = (props: { startTest: () => void; assignment: any }) => {
  const numQ = props.assignment?.questions?.length;
  const timedAssignment: boolean = props.assignment.timer;
  return (
    <div className="flex flex-col w-full h-full p-3 bg-white rounded-3xl">
      <div className="flex w-full mt-8 mb-3">
        <h2 className="text-xl font-bold uppercase mx-auto">Instructions</h2>
      </div>
      <div className="flex flex-col mt-8 w-3/5 mx-auto">
        <ul className="list-decimal whitespace-normal space-y-2">
          <li>
            There are <b>{numQ} Question(s)</b> in this Assignment.
          </li>
          {
            timedAssignment ? <li> Each question carries a Time Limit of <b>5 minutes</b> after which
              the Question will be skipped. The total duration of this test is{" "}{5 * numQ} minutes.</li> :
              <li> This assignment has no specified Time Limit. The objective of this assignment is to
                solve all questions with <b>Accuracy</b>.</li>
          }
          {
            timedAssignment && <li>Timer for each question is set to end exactly in 5 minutes from your <b>First View</b> of the question.</li>
          }
          <li>
            For optimal experience complete the assignment in <b>One Sitting</b>.
            In case you get dropped pickup from the question where you left.
          </li>

          <li>
            Questions can be revisited only after completing the assignment.
          </li>

          <li>
            For technical issues write to us at <b>support@tezprep.com</b> and for questions or solutions related queries
             write to us at <b>practice@tezprep.com</b>
          </li>

          <li>
            Click <b>Proceed</b> to start the test.
          </li>
        </ul>
        <div className="flex mt-16 justify-end mb-8">
          <GenericButton
            variant="primary"
            size="sm"
            className="p-2"
            onClick={props.startTest}
          >
            Proceed
          </GenericButton>
        </div>
      </div>
    </div>
  );
};

export default Instruction;
