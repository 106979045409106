import React, { useEffect, useState } from "react";
import { useDatabase, useUserObject } from "../hooks";
import { onValue, ref, set } from "firebase/database";
import { IdentificationIcon } from "@heroicons/react/24/outline";
import { Tab } from "@headlessui/react";
import { toast } from "react-toastify";
import Select from "react-select";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const AccountDashboard = () => {
    const database = useDatabase();
    const [userObject, setuserObject] = useUserObject() as any;
    const userDetailsRef = ref(database, "users/" + userObject.uid + "/data");
    const [userDetails, setUserDetails] = useState<any>(userObject);
    const [updatedUserDetails, setUpdatedUserDetails] = useState<any>(userObject);
    const [error, setError] = useState(false);

    const streamData = [
        { label: 'IIT', value: 1 },
        { label: 'NEET', value: 2 },
        { label: 'OLYMPIADS', value: 3 },
        { label: 'BOARDS', value: 4 }
    ];

    const classData = [
        { label: 'Class 12', value: 1 },
        { label: 'Class 11', value: 2 },
        { label: 'Class 10', value: 3 },
        { label: 'Class 9', value: 4 },
        { label: 'Class 6 to 8', value: 5 }
    ];

    useEffect(() => {
        onValue(userDetailsRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setUserDetails(data);
                setUpdatedUserDetails(data);
            }
            else {
                setError(true);
            }
        });
    }, []);

    const updateUserDetailsToDb = () => {
        set(userDetailsRef, updatedUserDetails)
            .then(() => {
                setuserObject(updatedUserDetails);
                toast.success("Update successful");
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error updating details. Please refresh and retry");
            });
    };

    return (
        <div className="flex flex-col flex-grow w-full bg-blue-50">
            <div className="flex justify-center text-[#121619] space-x-4 font-serif mt-12">
                <div className="flex h-10">
                    <IdentificationIcon />
                </div>
                <span className="whitespace-nowrap tracking-wide text-2xl/10">Manage Your Account</span>
            </div>
            <div className="flex w-full justify-center">
                <div className="w-full mt-8 max-w-2xl sm:px-0">
                    <Tab.Group>
                        <Tab.List className="flex space-x-1 justify-center rounded-xl bg-blue-600 p-1">
                            <Tab
                                className={() =>
                                    classNames(
                                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white",
                                        "ring-blue-600 ring-opacity-60 ring-offset-2 ring-offset-blue-600 focus:outline-none focus:ring-2",
                                        "bg-blue-600"
                                    )
                                }
                            >
                                Account Details
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className="w-full mt-2">
                            <Tab.Panel
                                className={classNames(
                                    "rounded-xl bg-white px-10 py-5",
                                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-[#2872fa] focus:outline-none focus:ring-2"
                                )}
                            >
                                <form
                                    className="w-full"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!error) {
                                            setUserDetails(updatedUserDetails);
                                            updateUserDetailsToDb();
                                        }
                                        else {
                                            console.log(error);
                                            toast.error("Error updating details. Please refresh and retry");
                                        }
                                    }}
                                >
                                    <div className="flex flex-row w-full space-x-5 mt-4 mb-8">
                                        <div className="w-1/3">
                                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="name"
                                                    type="text"
                                                    autoComplete="name"
                                                    minLength={2}
                                                    maxLength={50}
                                                    pattern="[a-zA-Z ]*"
                                                    defaultValue={updatedUserDetails.name}
                                                    required
                                                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#2872fa] sm:text-sm sm:leading-6 focus:outline-none"
                                                    onChange={(e) => {
                                                        setUpdatedUserDetails({ ...updatedUserDetails, name: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-1/3">
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Email address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    disabled={true}
                                                    type="email"
                                                    autoComplete="email"
                                                    defaultValue={updatedUserDetails.email}
                                                    required
                                                    className="block w-full bg-gray-200 rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring--[#2872fa] sm:text-sm sm:leading-6 focus:outline-none"
                                                />
                                            </div>
                                        </div>

                                        <div className="w-1/3">
                                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                                Mobile Number
                                            </label>
                                            <div className="mt-2">
                                                {/* To update with intl-tel-input for later and add verification. */}
                                                <input
                                                    id="phone"
                                                    type="tel"
                                                    autoComplete="tel"
                                                    maxLength={10}
                                                    minLength={10}
                                                    pattern="[0-9]{10}"
                                                    defaultValue={updatedUserDetails.phone}
                                                    required
                                                    className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-[#2872fa] sm:text-sm sm:leading-6 focus:outline-none"
                                                    onChange={(e) => {
                                                        setUpdatedUserDetails({ ...updatedUserDetails, phone: e.target.value })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-row w-full space-x-5">
                                        <div className="w-1/3">
                                            <label htmlFor="class" className="block text-sm font-medium leading-6 text-gray-900">
                                                Class
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={classData}
                                                    onChange={(e) => { if (e !== null) setUpdatedUserDetails({ ...updatedUserDetails, class: e.label }) }}
                                                    defaultValue={classData.filter(option => option.label === updatedUserDetails.class)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="w-2/3">
                                            <label htmlFor="stream" className="block text-sm font-medium leading-6 text-gray-900">
                                                Target Stream <span className="text-gray-900">(in descending order of priority)</span>
                                            </label>
                                            <div className="mt-2">
                                                <Select
                                                    options={streamData}
                                                    onChange={(e) => {
                                                        let updatedStream: string[] = [];
                                                        e.forEach(element => updatedStream.push(element.label))
                                                        setUpdatedUserDetails({ ...updatedUserDetails, stream: updatedStream })
                                                    }}
                                                    defaultValue={streamData.filter(element => updatedUserDetails.stream.includes(element.label))}
                                                    required
                                                    isMulti={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-center mt-10 mb-2.5">
                                        <button
                                            type="submit"
                                            disabled={JSON.stringify(userDetails) === JSON.stringify(updatedUserDetails)}
                                            className={
                                                JSON.stringify(userDetails) === JSON.stringify(updatedUserDetails) ?
                                                    "flex w-1/4 justify-center rounded-md bg-slate-600 opacity-90 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" :
                                                    "flex w-1/4 justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                            }
                                        >
                                            Update Details
                                        </button>
                                    </div>
                                </form>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );

};

export default AccountDashboard;        
