import { Tab } from "@headlessui/react";
import React, { useEffect } from "react";
import {
  QuestionMarkCircleIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const QuestionSolutionTab = ({
  question,
  solution,
  showSolution,
}: {
  question: any;
  solution: any;
  showSolution: boolean;
}) => {
  useEffect(() => {}, []);

  return (
    <Tab.Group>
      {showSolution && (
        <Tab.List className="flex space-x-4 rounded-xl p-1">
          {["Question", "Solution"].map((category, idx) => (
            <Tab
              key={idx}
              className={({ selected }) =>
                classNames(
                  "flex w-32 rounded-lg py-2.5 text-sm font-medium leading-5",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-yellow-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-yellow-500 text-white shadow"
                    : "text-yellow-500 hover:bg-white/[0.12] hover:text-yellow-400"
                )
              }
            >
              {category === "Question" ? (
                <QuestionMarkCircleIcon className="m-2 h-4 w-4 my-auto" />
              ) : (
                <Squares2X2Icon className="m-2 h-4 w-4 my-auto" />
              )}
              {category}
            </Tab>
          ))}
        </Tab.List>
      )}
      <Tab.Panels className="mt-1">
        <Question>{question}</Question>
        <Solution>{solution}</Solution>
      </Tab.Panels>
    </Tab.Group>
  );
};

const Question = (props: any) => (
  <Tab.Panel
    key={0}
    className={classNames(
      "rounded-xl p-3",
      "ring-white ring-opacity-60 focus:outline-none"
    )}
  >
    {props.children}
  </Tab.Panel>
);

const Solution = (props: any) => (
  <Tab.Panel
    key={1}
    className={classNames(
      "rounded-xl p-3",
      "ring-white ring-opacity-60 focus:outline-none"
    )}
  >
    {props.children}
  </Tab.Panel>
);

export default QuestionSolutionTab;
