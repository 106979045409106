import React from "react";
import QuestionPopup from "../components/Questions/QuestionPopup";
import { isEmptyHTMLString, isEmptyOrUndefined } from "../util";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useUserObject } from "../hooks";

const QuestionsTable = (props: {
  data: any;
  addQuestion: (qPth: string) => void;
  removeQuestion: (qPth: string) => void;
}) => {
  const [userObject, setuserObject] = useUserObject() as any;

  const validateQS = (s: string) => {
    if (isEmptyOrUndefined(s) || isEmptyHTMLString(s)) {
      return <XCircleIcon className="w-6 h-6 mx-auto text-red-600" />;
    }
    return <CheckCircleIcon className="w-6 h-6 mx-auto text-green-600" />;
  };
  return (
    <table className="table-auto w-full border-collapse border border-slate-500">
      <div className="p-1">
        <span className=" text-blue-600 font-medium cursor-pointer hover:underline">
          <QuestionPopup data={{}}>+ Add new Question</QuestionPopup>
        </span>
      </div>

      <thead className="bg-yellow-200">
        <tr>
          <th className="p-4 border border-slate-600">Question</th>
          <th className="p-4 border border-slate-600">IsQuestionDone</th>
          <th className="p-4 border border-slate-600">IsAnswerDone</th>
          <th className="p-4 border border-slate-600">IsSolutionDone</th>
        </tr>
      </thead>
      <tbody className="bg-slate-50 items-center">
        {props.data &&
          Object.keys(props.data).map((q, idx) => {
            return (
              <tr key={idx} className="border border-slate-600">
                <td className="p-2 text-yellow-500">
                  <div className="flex">
                    <input
                      type="checkbox"
                      className="me-4"
                      onChange={(event) => {
                        if (event.target.checked) {
                          props.addQuestion(
                            "users/" + userObject.uid + "/questions/" + q
                          );
                        } else {
                          props.removeQuestion(
                            "users/" + userObject.uid + "/questions/" + q
                          );
                        }
                      }}
                    />
                    <QuestionPopup data={props.data[q]} questionId={q}>
                      {"Question " + (idx + 1)}
                    </QuestionPopup>
                  </div>
                </td>
                <td>{validateQS(props.data[q]?.question)}</td>
                <td>{validateQS(props.data[q]?.answerObject)}</td>
                <td>{validateQS(props.data[q]?.solution)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default QuestionsTable;
