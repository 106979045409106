import React, { useEffect, useState } from "react";
import { useUserObject } from "../hooks";
import { firstLetterCapitalize } from "../util";
import { AcademicCapIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const HomeDashboard = () => {
    const [userObject, setUserObject] = useUserObject() as any;
    return (
        <div className="flex flex-col w-full flex-grow bg-slate-200 pt-14 pb-20 space-y-8 items-center">
            <div className="flex w-3/4 w-full justify-center text-[#121619] space-x-3 font-serif">
                <div className="flex h-10">
                    <AcademicCapIcon />
                </div>
                <span className="whitespace-nowrap tracking-wide text-2xl/10">Welcome {firstLetterCapitalize(userObject.name)},
                    Glad to have you onboard.</span>
            </div>
            <div className="flex flex-row items-center text-lg">
                Quick links : <Link to="/assignments" className="mx-2 no-underline text-base text-white bg-blue-700 rounded-md px-3 py-1.5 hover:opacity-60">
                    Assignments
                </Link> | <Link to="/account"
                    className="mx-2 no-underline text-base text-white bg-blue-700 rounded-md px-2 py-1.5 hover:opacity-60">Account Manager</Link>
            </div>
            <div className="flex w-4/5 font-medium text-gray-800 text-center justify-center">
                For tailored practice recommendations, update your class and target stream details in the Account Manager dashboard.
            </div>
            <div className="flex w-full justify-center">
                <div className="flex flex-col rounded-3xl w-2/3 bg-white items-center space-y-8
                border-2 border-slate-200 shadow-sm pt-10 pb-10">
                    {/* Section: Beta stage content */}
                    <div className="w-5/6">
                        Thank you for signing up! Get good exposure to top-tier problems that test your concept clarity and enhance your speed and accuracy while solving.
                        Sharpen your skill with timed previous year questions. All within a single platform.
                    </div>

                    <div className="w-5/6">
                        <div className="flex flex-col font-semibold">What do you get?</div>
                        You are currently using a beta version of our problem-solving dashboard with exciting features planned for launch in our pipeline.
                        Enjoy solving intriguing problems that add immense value and boost your problem-solving acumen.
                        At the moment, we only cover questions from <b>Physics and Math</b>.
                    </div>

                    <div className="w-5/6">
                        <div className="flex flex-col font-semibold">Who we are?</div>
                        <span>
                        We are a group of IITians and BITSians driven by the vision of optimizing the learning process towards
                         problem-solving. We are on a mission to create a powerful practice platform in a fully data-oriented
                         approach equipped with a robust multidimensional data analytics model.
                        </span>

                    </div>

                    <div className="w-5/6">
                        On top of this practice platform, we offer an intuitive and non-routine approach to learning concepts.
                        If you would like to know more about our learning programs for the Online JEE crash course or Admissions
                        for 2024-25, get in touch with us via mail - <b>support@tezprep.com</b> or Whatsapp - <b>(+91)7904989034</b>
                    </div>
                </div>
            </div>

            <div className="flex w-3/5 font-medium text-gray-800 text-center justify-center">
                You are one of our most valuable early users. Please share your thoughts,  feedback, suggestions, or features
                you would like to see on our dashboard and help us build a tailored product for the student community.
            </div>

            <div className="flex flex-row w-2/3 items-center h-full">
                <div className="flex flex-col mr-4 items-center py-6 w-1/2 bg-white rounded-xl border-2 border-slate-200 shadow-sm">
                    <div className="flex px-7 text-center">For technical issues, feedback or suggestions write to us at</div>
                    <div className="flex mt-4 justify-center items-center rounded-xl bg-blue-600 px-5 py-3 text-white">
                        support@tezprep.com
                    </div>
                </div>
                <div className="flex flex-col ms-4 items-center py-6 w-1/2 bg-white rounded-xl border-2 border-slate-200 shadow-sm">
                    <div className="flex px-7 text-center">For questions, answer key or solution queries write to us at</div>
                    <div className="flex mt-4 justify-center items-center rounded-xl bg-blue-600 px-5 py-3 text-white">
                        practice@tezprep.com
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HomeDashboard;
