import { getDatabase, ref, push } from "firebase/database";
import firebase_app from "./firebase-config";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";

const database = getDatabase(firebase_app);

const userAtom = atomWithStorage("userAtom", {});
const answerAtom = atomWithStorage("answerAtom", {});
const errorsAtom = atomWithStorage("errorsAtom", {});

export const useUserObject = () => {
  return useAtom(userAtom);
};

export const useAnswerObject = () => {
  return useAtom(answerAtom);
};

export const useErrorsObject = () => {
  return useAtom(errorsAtom);
};

export const useAllQuestionsRef = () => {
  const [userObject, setuserObject] = useUserObject() as any;
  return ref(database, "users/" + userObject.uid + "/questions");
};

export const useDatabase = () => {
  return database;
};
