import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useDatabase } from "../hooks";
import AssignmentIcon from "./AssignmentIcon";
import AssignmentCard from "./AssignmentCard";

const AssignmentsContent = () => {
  const [AllAssignments, setAllAssignments] = useState<any>({});
  const database = useDatabase();
  const assignmentsRef = ref(database, "assignments");

  useEffect(() => {
    onValue(assignmentsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAllAssignments(data);
      }
    });
  }, []);

  return (
    <div className="flex flex-col w-full p-12 space-y-3 items-center">
      <div className="flex w-4/5 justify-start text-[#121619] space-x-2 font-serif">
        <div className="flex h-10">
          <AssignmentIcon />
        </div>
        <span className="whitespace-nowrap tracking-wide text-2xl/10">Practice Assignments</span>
      </div>
      <div className="flex justify-center pt-9 w-full">
      <div className="flex grid gap-x-9 gap-y-10 grid-cols-4 z-0 col-auto">
        {Object.keys(AllAssignments).map((ass, idx) => {
          return (
            <AssignmentCard key={idx} data={AllAssignments[ass]} assId={ass} />
          );
        })}
      </div>
      </div>
    </div>
  );
};

export default AssignmentsContent;
