import React, { useEffect, useState } from "react";
import QuestionProgressStepper from "./QuestionProgressStepper";
import { ClockIcon } from "@heroicons/react/20/solid";
import TestQuestion from "./TestQuestion";
import ToggleFullscreenButton from "./ToggleFullscreenButton";
import { FullScreenHandle } from "react-full-screen";
import Scorecard from "./Scorecard";
import { set } from "firebase/database";

const Test = (props: {
  assignment: any;
  assignmentId: string;
  fullScreenHandle: FullScreenHandle;
  userAssignmentDetails: any;
  userAssignmentRef: any;
}) => {
  const numQ = props.assignment?.questions?.length;
  const [timedAssignment, setTimedAssignment] = useState(props.assignment.timer);
  const assignmentRef = props.userAssignmentRef;
  const [currQ, setcurrQ] = useState(props.userAssignmentDetails.currQ);
  const [testStatus, settestStatus] = useState<"RUNNING" | "FINISHED" | "REVIEW">(
    props.userAssignmentDetails.testStatus
  );
  const [timer, settimer] = useState();
  const [answers, setanswers] = useState<any[]>(props.userAssignmentDetails.hasOwnProperty("answers") ? 
  (props.userAssignmentDetails)["answers"] : []);
  const [score, setscore] = useState(props.userAssignmentDetails.score);
  const startTime = Date.parse(props.userAssignmentDetails.startTime);
  const [currQuesStartTime, setCurrQuesStartTime] = useState<number>(props.userAssignmentDetails.hasOwnProperty("answers") ?
  Date.parse((props.userAssignmentDetails.answers[props.userAssignmentDetails.answers.length - 1])["currQuesEndTime"]) :
  startTime);
  let saveUpdate = props.userAssignmentDetails.testStatus === "RUNNING" ? true : false;

  const saveTest = () => {
    set(assignmentRef, { startTime: new Date(startTime).toUTCString(), answers, testStatus, currQ, score })
      .then(() => {
        // toast.success("saved");
      })
      .catch((err) => {
        // toast.error("error");
      });
  };

  useEffect(() => {
    if (testStatus === "REVIEW" && currQ === numQ + 1) {
      setcurrQ(1);
    }
    setTimedAssignment(timedAssignment && testStatus === "RUNNING");
  }, [testStatus]);

  useEffect(() => {
    if (saveUpdate && testStatus !== "REVIEW") {
      saveTest();
    }
  }, [score, answers, currQuesStartTime]);

  return (
    <div className="flex flex-col bg-white rounded-3xl w-full h-full">
      <div className="flex w-full bg-[#3A4F66] p-2 rounded-t-2xl justify-between">
        <div className="flex">
          <div className="flex p-3 bg-slate-100 rounded-xl me-4">
            <QuestionProgressStepper
              currentQuestion={currQ}
              numQuestion={numQ}
              onQuesClick={(quesNumber: Number) => {
                if (testStatus === "REVIEW" || testStatus === "FINISHED") {
                  setcurrQ(quesNumber);
                  settestStatus("REVIEW");
                }
              }}
            />
          </div>

          {
            timedAssignment && <div className="flex p-2 bg-slate-100 rounded-xl space-x-2">
              <ClockIcon className="h-6 my-auto text-yellow-500" />
              <span className="my-auto font-mono">{timer}</span>
            </div>
          }

        </div>
        <ToggleFullscreenButton fullScreenHandle={props.fullScreenHandle} />
      </div>

      <div className="flex w-full h-full mb-3">
        {testStatus === "RUNNING" ? (
          <TestQuestion
            key={currQ}
            currentQuestion={currQ}
            numQuestion={numQ}
            testStatus={testStatus}
            qPth={props.assignment?.questions[currQ - 1]}
            settimer={settimer}
            currQuesStartTime={currQuesStartTime}
            timed={timedAssignment}
            goNext={(isCorrect: boolean, answer: any, timeTaken: number) => {
              setcurrQ(currQ + 1);
              setanswers((answers) => [
                ...answers,
                { isCorrect, answer, timeTaken, currQuesStartTime: new Date(currQuesStartTime).toUTCString(),
                currQuesEndTime: new Date(Date.now()).toUTCString() },
              ]);
              setCurrQuesStartTime(Date.now());
              if (currQ === numQ) {
                settestStatus("FINISHED");
              }
              if (isCorrect) {
                setscore(score + 1);
              }
            }}
          />
        ) : testStatus === "FINISHED" ? (
          <Scorecard
            score={score}
            numQ={numQ}
            reviewTest={(isReview: boolean) => {
              if (isReview) {
                settestStatus("REVIEW");
              }
            }} />
        ) : (
          <TestQuestion
            key={currQ}
            currentQuestion={currQ}
            numQuestion={numQ}
            testStatus={testStatus}
            qPth={props.assignment?.questions[currQ - 1]}
            settimer={settimer}
            currQuesStartTime={currQuesStartTime}
            timed={timedAssignment}
            goNext={(isCorrect: boolean, answer: any, timeTaken: number) => {
              setcurrQ(currQ + 1);
              if (currQ === numQ) {
                settestStatus("FINISHED");
              }
            }}
          />
        )

        }
      </div>
    </div>
  );
};

export default Test;
