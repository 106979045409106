import React, { useState, useEffect } from "react";
import { ClockIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { useDatabase, useUserObject } from "../hooks";

const AssignmentCard = (props: { data: any; assId: string }) => {
  const [userAssignment, setUserAssignment] = useState<any>({});
  const database = useDatabase();
  const [userObject, setuserObject] = useUserObject() as any;
  const userAssignmentRef = ref(
    database,
    "users/" + userObject.uid + "/assignments/" + props.assId
  );

  useEffect(() => {
    onValue(userAssignmentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setUserAssignment(data);
      }
    });
  }, []);

  const AssignmentCardContents = () => {
    if (userAssignment.testStatus === "FINISHED") {
      return <div className="flex flex-col w-[250px] h-[250px] rounded-3xl bg-slate-200 shadow-lg cursor-pointer hover:scale-110 hover:transition-transform">
       <div className={props.data.subject === "Mathematics" ? "flex w-full rounded-t-2xl h-1/3 bg-blue-600" : 
      props.data.subject === "Physics" ? "flex w-full rounded-t-2xl h-1/3 bg-red-600": "flex w-full rounded-t-2xl h-1/3 bg-teal-500"}>
          <span className="text-white mx-auto my-auto text-2xl font-semibold z-10">
            {props.data.subject}
          </span>
        </div>

        <div className="w-full h-full text-white">
          <div className="flex justify-center p-4 text-sm">
            {props.data.timer ? (
              <div className="flex space-x-1 text-green-600">
                <div className="flex h-4 my-auto">
                  <ClockIcon />
                </div>
                <span className="my-auto">Timed</span>
              </div>
            ) : (
              <span className="text-gray-500">Not timed</span>
            )}
          </div>
          <div className="flex mx-auto justify-center text-yellow-900 uppercase text-lg font-semibold whitespace-nowrap">
            {props.data.name}
          </div>
          <div className="flex justify-center p-4 text-zinc-800">
            Completed
          </div>
        </div>
      </div>
    }

    else {
      return <div className="flex flex-col w-[250px] h-[250px] rounded-3xl bg-slate-50 shadow-lg cursor-pointer hover:scale-110 hover:transition-transform">
      <div className={props.data.subject === "Mathematics" ? "flex w-full rounded-t-2xl h-1/3 bg-blue-600" : 
      props.data.subject === "Physics" ? "flex w-full rounded-t-2xl h-1/3 bg-red-600": "flex w-full rounded-t-2xl h-1/3 bg-teal-500"}>
        <span className="text-white mx-auto my-auto text-2xl font-semibold z-10">
          {props.data.subject}
        </span>
      </div>

      <div className="w-full h-full text-white">
        <div className="flex justify-center p-4 text-sm">
          {props.data.timer ? (
            <div className="flex space-x-1 text-green-600">
              <div className="flex h-4 my-auto">
                <ClockIcon />
              </div>
              <span className="my-auto">Timed</span>
            </div>
          ) : (
            <span className="text-gray-500">Not timed</span>
          )}
        </div>
        <div className="flex mx-auto my-auto justify-center text-yellow-900 uppercase text-lg font-semibold whitespace-nowrap">
          {props.data.name}
        </div>
        <div className="flex justify-center p-4 text-zinc-800">
          {userAssignment.testStatus === "RUNNING" ? "In Progress" : "Click to Start"}
        </div>
      </div>
    </div>
    }

  }

  return (
    <Link to={"/test/" + props.assId} className="no-underline">
      <AssignmentCardContents />
    </Link>
  );
};

export default AssignmentCard;
