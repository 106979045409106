import React from "react";
import CQFormat from "./CQFormat";
import SubjectiveFormat from "./SubjectiveFormat";

const AnswerFormat = (props: {
  mode: "Edit" | "Preview" | "Solve" | "SolutionCheck";
  questionType: string;
}) => {
  return (
    <div className="flex flex-col w-full">
      {props.questionType === "Choice Question" ? (
        <CQFormat mode={props.mode} />
      ) : (
        <SubjectiveFormat mode={props.mode} />
      )}
    </div>
  );
};

export default AnswerFormat;
