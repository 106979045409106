import Countdown, { zeroPad } from "react-countdown";
import QuestionView from "../components/Questions/QuestionView";
import { useAnswerObject, useDatabase } from "../hooks";
import { onValue, ref } from "firebase/database";
import React, { Suspense, useEffect, useState } from "react";
import GenericButton from "../components/GenericButton";
import { matchAnswers } from "../util";

function removeProp(obj: any, propToDelete: any) {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] == "object") {
        removeProp(obj[property], propToDelete);
      } else {
        if (property === propToDelete && obj[property] === true) {
          delete obj[property];
        }
      }
    }
  }
}

const TestQuestion = (props: {
  currentQuestion: number,
  numQuestion: number,
  testStatus: "RUNNING" | "FINISHED" | "REVIEW",
  qPth: string;
  settimer: (timer: any) => void;
  currQuesStartTime: any;
  timed: boolean;
  goNext: (isCorrect: boolean, answer: any, timeTaken: number) => void;
}) => {
  const database = useDatabase();
  const questionRef = ref(database, props.qPth);
  const [question, setquestion] = useState();
  const [answer, setanswer] = useState();
  const [answerObject, setanswerObject] = useAnswerObject() as any;
  const [timeElapsed, settimeElapsed] = useState(0);

  const renderer = ({ minutes, seconds }: any) => {
    settimeElapsed(5 * 60 - (minutes * 60 + seconds));
    return (
      <span className="text-red-600 text-lg font-bold">
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };
  
  const isCorrect = () => {
    return matchAnswers(answer, answerObject);
  };

  useEffect(() => {
    onValue(questionRef, (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        let aobj = JSON.parse(JSON.stringify(data));
        setanswer(aobj["answerObject"]);
        if(props.testStatus !== "REVIEW"){
          removeProp(data, "isSelected");  // To update later: A variable to show user selection as well.
        }
        setquestion(data);
      }
    });
  
    props.timed && props.settimer(
      <Countdown
        date={props.currQuesStartTime + 5 * 60 * 1000}
        renderer={renderer}
        onComplete={() => {
          props.settimer(renderer({ minutes: 0, seconds: 0 }));
          props.goNext(isCorrect(), answerObject, 5 * 60);
        }}
      />
    );
  }, [answerObject]);

  return (
    <div className="flex flex-col w-full">
      {!question ? (
        ""
      ) : (
        <Suspense fallback={"Loading..."}>
          <QuestionView
            data={question}
            writeQuestionToDb={() => {}}
            editMode={false}
            showSolution={false || props.testStatus === "REVIEW"}
          />
          <div className="flex place-self-end mx-8 space-x-4 mb-8">
            <GenericButton
              variant="primary"
              size="md"
              onClick={() => {
                props.timed && props.settimer(renderer({ minutes: 0, seconds: 0 }));
                props.goNext(isCorrect(), answerObject, timeElapsed);
              }}
            >
              {props.currentQuestion === props.numQuestion ? "View Score" : "Next Question"}
            </GenericButton>
            {/* <GenericButton variant="tertiary" size="md">
              Skip &#187;
            </GenericButton> */}
          </div>
        </Suspense>
      )}
    </div>
  );
};

export default TestQuestion;
