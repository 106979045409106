import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { UserIcon, ArrowRightOnRectangleIcon, IdentificationIcon} from "@heroicons/react/20/solid";
import { useUserObject } from "../hooks";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";


const ProfileButton = () => {
  const [userObject, setuserObject] = useUserObject();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="relative flex rounded-full bg-slate-300 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-[#1559ed]">
          <UserIcon className="h-8 w-8 p-1 rounded-full" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Link to="/account" className="no-underline">
        <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-gray-500 text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
              >
                <div className="flex">
                  <IdentificationIcon className="w-6 h-6 me-3" />
                  Account Manager
                </div>
              </button>
            )}
          </Menu.Item>
        </Link>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-gray-500 text-white" : "text-gray-900"
                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                onClick={() => {
                  setuserObject({});
                  toast.success("Logout successful");
                }}
              >
                <div className="flex">
                  <ArrowRightOnRectangleIcon className="w-6 h-6 me-3" />
                  Logout
                </div>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileButton;
