import React, { useState } from "react";
import { FullScreenHandle } from "react-full-screen";
import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/outline";

const ToggleFullscreenButton = (props: {
  fullScreenHandle: FullScreenHandle;
}) => {
  const [isFull, setisFull] = useState(props.fullScreenHandle.active);
  return (
    <div
      className="flex bg-slate-100 p-3 my-auto rounded-md hover:text-slate-100 hover:bg-yellow-900 cursor-pointer"
      onClick={() => {
        if (isFull) {
          props.fullScreenHandle.exit();
          setisFull(false);
        } else {
          props.fullScreenHandle.enter();
          setisFull(true);
        }
      }}
    >
      {isFull ? (
        <ArrowsPointingInIcon className="h-6 w-6" />
      ) : (
        <ArrowsPointingOutIcon className="h-6 w-6" />
      )}
    </div>
  );
};

export default ToggleFullscreenButton;
