import React from "react";

const GenericInput = (props: {
  variant: "primary" | "secondary" | "tertiary";
  size: "sm" | "md";
  className?: string;
  onChange?: any;
  type:
    | "text"
    | "date"
    | "datetime-local"
    | "number"
    | "password"
    | "email"
    | "file";
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  defaultValue?: string;
  accept?: string;
}) => {
  const sizeClass = {
    sm: "h-auto p-2 rounded-md ",
    md: "h-auto p-4 rounded-md ",
  };

  const borderClass =
    "border border-gray-600 focus:ring-blue-500 focus:border-blue-500 ";
  const variantClass = {
    primary: "bg-slate-50 text-black ",
    secondary: "text-white bg-violet-500 ",
    tertiary: "text-white bg-zinc-600 ",
  };

  const className =
    sizeClass[props.size] +
    borderClass +
    variantClass[props.variant] +
    "hover:opacity-80 " +
    (props.className || "");

  return (
    <div className="flex flex-col">
      {props.label && (
        <label className="text-black font-medium ">{props.label}</label>
      )}
      <input
        type={props.type}
        id={props.id}
        className={className}
        placeholder={props.placeholder}
        required={props.isRequired}
        onChange={(event) => {
          if (props.type === "file") {
            if (event.target.files) {
              props.onChange(event.target.files[0]);
            }
          } else {
            props.onChange(event.target.value);
          }
        }}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        accept={props.accept}
      />
    </div>
  );
};

export default GenericInput;
