import React, { useState } from "react";
import GenericButton from "../components/GenericButton";
import { Link } from "react-router-dom";


const Scorecard = (props: {
  score: number,
  reviewTest: (isReview: boolean) => void;
  numQ: number
}) => {

  const AppreciationMessage = () => {
    if (props.score >= 0.8 * props.numQ) {
      return "Great performance. Keep the momentum going. You have completed this Assignment.";
    }
    else if (props.score >= 0.5 * props.numQ) {
      return "Good work. Keep the momentum going. You have completed this Assignment.";
    }
    else {
      return "Good try. Keep the momentum going. You have completed this Assignment.";
    }
  }

  return (
    <div className="flex flex-col w-full h-full items-center p-5 bg-white rounded-3xl">
      <div className="flex w-full justify-center mt-3 mb-10">
        <div className="text-xl text-center font-bold justify-center uppercase">Test Complete</div>
      </div>
      <div className="text-lg text-center mb-10">
        {AppreciationMessage()}
      </div>
      <div className="text-lg text-center mb-10">
        Your score is : <span className="ps-1.5 font-semibold text-teal-700">{props.score + " out of " + props.numQ}</span>.
      </div>
      <div className="whitespace-normal text-center mb-7">
        Click on the question number directly or click <b>Review Question</b> to view each question and its answer key.
      </div>
      <div className="whitespace-normal text-center">
        For detailed solutions contact the admin - <b>practice@tezprep.com</b> or Whatsapp - <b>(+91)7904989034</b>
      </div>
      <div className="flex w-full space-x-14 justify-center mt-14 mb-8">
        <GenericButton
          variant="quaternary"
          size="md"
          onClick={() => {
            props.reviewTest(true);
          }}
        >
          Review Questions
        </GenericButton>
        <Link to="/assignments" className="no-underline my-auto">
          <GenericButton
            variant="quaternary"
            size="md"
          >
            Close Assignment
          </GenericButton>
        </Link>
      </div>
    </div>
  );
};

export default Scorecard;
