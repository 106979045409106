import React from "react";

const AssignmentIcon = () => {
  return (
    <svg fill="currentColor" viewBox="0 0 418.089 418.089">
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path d="M153.844,214.399l26.438,59.146c1.127,2.521,3.63,4.144,6.391,4.144s5.264-1.623,6.391-4.144l26.456-59.186 c0.171-0.364,0.679-2.492,0.66-3.164V42.289c0-2.259-0.434-4.418-1.223-6.399V16.875C218.957,7.57,211.386,0,202.082,0h-30.816 c-9.306,0-16.876,7.57-16.876,16.875v19.011c-0.789,1.982-1.224,4.142-1.224,6.402v168.947 C153.154,211.74,153.598,213.882,153.844,214.399z M186.672,253.536l-15.71-35.147h31.421L186.672,253.536z M168.389,16.875 c0-1.559,1.317-2.875,2.876-2.875h30.816c1.559,0,2.875,1.317,2.875,2.875v8.19c-0.699-0.086-1.41-0.13-2.132-0.13h-16.009 c-0.096-0.002-0.191-0.002-0.287,0h-16.011c-0.721,0-1.431,0.044-2.129,0.13V16.875z M167.166,42.289 c0-2.745,2.354-3.354,3.353-3.354h9.154v94.504c0,3.866,3.134,7,7,7s7-3.134,7-7V38.935h9.152c0.999,0,3.354,0.422,3.354,3.354 v162.1h-39.014V42.289z M357.796,24.935H245.088c-3.866,0-7,3.134-7,7s3.134,7,7,7h112.708c4.299,0,7.797,3.498,7.797,7.796v349.56 c0,4.299-3.498,7.797-7.797,7.797H101.782V38.935h26.51c3.866,0,7-3.134,7-7s-3.134-7-7-7H56.695c-3.866,0-7,3.134-7,7v21.432 h-4.199c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.199v39.714h-4.199c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.199v39.715h-4.199 c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.199v39.715h-4.199c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.199v39.714h-4.199c-3.866,0-7,3.134-7,7 s3.134,7,7,7h4.199v39.715h-4.199c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.199v39.714h-4.199c-3.866,0-7,3.134-7,7s3.134,7,7,7h4.199 v21.435c0,3.866,3.134,7,7,7h301.102c12.019,0,21.797-9.778,21.797-21.797V46.731C379.593,34.713,369.815,24.935,357.796,24.935z M87.782,404.089H63.695v-14.435h4.199c3.866,0,7-3.134,7-7s-3.134-7-7-7h-4.199V335.94h4.199c3.866,0,7-3.134,7-7s-3.134-7-7-7 h-4.199v-39.715h4.199c3.866,0,7-3.134,7-7s-3.134-7-7-7h-4.199v-39.714h4.199c3.866,0,7-3.134,7-7s-3.134-7-7-7h-4.199v-39.715 h4.199c3.866,0,7-3.134,7-7s-3.134-7-7-7h-4.199v-39.715h4.199c3.866,0,7-3.134,7-7s-3.134-7-7-7h-4.199V67.367h4.199 c3.866,0,7-3.134,7-7s-3.134-7-7-7h-4.199V38.935h24.087V404.089z"></path>
      </g>
    </svg>
  );
};

export default AssignmentIcon;
