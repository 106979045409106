import React, { useEffect, useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import BlotFormatter from "quill-blot-formatter";
import katex from "katex";
import "katex/dist/katex.min.css";
import "./RTEditor.style.scss";

Quill.register("modules/blotFormatter", BlotFormatter);

const toolbarOptions = [
  [{ header: "1" }, { header: "2" }, { font: ["Lato,sans-serif"] }],
  [{ size: ["small", false, "large", "huge"] }],

  ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video", "formula"], //, "image", "video"
  ["clean"],
];

const RichTextEditor = (props: { value: string; updateValue: any }) => {
  useEffect(() => {
    window.katex = katex;
  }, []);

  return (
    <div
      className="flex flex-col w-full h-full overflow-auto"
      data-text-editor="name"
    >
      <ReactQuill
        theme="snow"
        defaultValue={props.value}
        onChange={props.updateValue}
        className="w-full h-full text-lg overflow-auto"
        bounds={`[data-text-editor="name"]`}
        modules={{
          toolbar: toolbarOptions,
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          blotFormatter: {},
        }}
        placeholder={"Write something..."}
      />
    </div>
  );
};

export default RichTextEditor;
