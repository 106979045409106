import React, { useEffect, useState } from "react";
import { onValue, push, ref, set } from "firebase/database";
import { useAllQuestionsRef, useDatabase, useUserObject } from "../hooks";
import QuestionsTable from "./QuestionsTable";
import GenericButton from "../components/GenericButton";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/20/solid";
import GenericInput from "../components/GenericInput";
import GenericDropdown from "../components/GenericDropdown";
import { useNavigate } from "react-router-dom";

const QuestionsContent = () => {
  const allquestionsRef = useAllQuestionsRef();
  const [AllQuestions, setAllQuestions] = useState<any>({});
  const [userObject, setuserObject] = useUserObject() as any;
  const [SelectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const navigate = useNavigate();
  const [showAssignmentWindow, setshowAssignmentWindow] =
    useState<boolean>(false);
  const database = useDatabase();
  const [aw_name, setaw_name] = useState("");
  const [aw_timer, setaw_timer] = useState(false);
  const [aw_subject, setaw_subject] = useState("Physics");

  const createAssignment = () => {
    let aRef = ref(database, "assignments");
    let id = push(aRef).key;

    const assignmentRef = ref(database, "assignments/" + id);
    set(assignmentRef, {
      name: aw_name,
      timer: aw_timer,
      subject: aw_subject,
      questions: SelectedQuestions,
    })
      .then(() => {
        toast.success("Successfully created Assignment!");
        setshowAssignmentWindow(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error pushing to DB");
      });
  };

  useEffect(() => {
    if (userObject.role === "STUDENT") {
      navigate("/");
    }
    onValue(allquestionsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAllQuestions(data);
      }
    });
  }, []);

  return (
    <div className="flex w-full">
      <div className="flex flex-col w-6/12 mx-auto mt-8">
        <div className="flex mb-4 justify-start">
          <GenericButton
            variant="tertiary"
            size={"sm"}
            onClick={() => {
              if (SelectedQuestions.length) {
                setshowAssignmentWindow(true);
              } else {
                toast.warn("Select Atleast one Question");
              }
            }}
          >
            Create Assignment
          </GenericButton>
        </div>
        <QuestionsTable
          data={AllQuestions}
          addQuestion={(qPth: string) => {
            setSelectedQuestions([...SelectedQuestions, qPth]);
          }}
          removeQuestion={(qPth: string) => {
            const q = SelectedQuestions.filter((v) => v !== qPth);
            setSelectedQuestions(q);
          }}
        />
      </div>
      {showAssignmentWindow && (
        <div className="flex flex-col p-2 bg-white w-3/12 h-screen">
          <div
            className="flex h-8 w-20 text-red-500 cursor-pointer"
            onClick={() => {
              setshowAssignmentWindow(false);
            }}
          >
            <XMarkIcon />
            <span className="my-auto">Close</span>
          </div>

          <div className="flex flex-col mt-16 mx-auto">
            <div className="flex flex-col space-y-4">
              <GenericInput
                variant={"primary"}
                size={"sm"}
                type={"text"}
                placeholder="Assignment Name"
                defaultValue={aw_name}
                onChange={(v: string) => {
                  setaw_name(v);
                }}
              />
              <div className="flex mx-auto space-x-3">
                <span>Timed: </span>
                <input
                  type="checkbox"
                  defaultChecked={aw_timer}
                  onChange={(event) => {
                    setaw_timer(event.target.checked);
                  }}
                />
              </div>
              <GenericDropdown
                options={["Physics", "Chemistry", "Mathematics"]}
                updateValue={setaw_subject}
                defaultValue={aw_subject}
              />
            </div>
            <GenericButton
              variant="primary"
              size={"sm"}
              className="mt-8"
              onClick={createAssignment}
            >
              Create
            </GenericButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsContent;
