import React from "react";
import ProfileButton from "./components/ProfileButton";
import { useUserObject } from "./hooks";
import { Link, useLocation } from "react-router-dom";
import { isEmptyOrUndefined } from "./util";

const menuTabs = [
  {
    title: "Assignments",
    link: "/assignments",
    roles: ["STUDENT", "ADMIN", "SUPERADMIN"],
  },
  {
    title: "Challenges",
    link: "/challenges",
    roles: ["ADMIN", "SUPERADMIN"], // Note: To be updated later.
  },
  { title: "Questions", link: "/questions", roles: ["ADMIN", "SUPERADMIN"] },
];

const NavMenu = () => {
  const [userObject, setuserObject] = useUserObject() as any;
  const { pathname } = useLocation();

  return (
    <nav className="bg-white min-h-24 w-full fixed shadow-lg z-10 divide-y shadow-md">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-24 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center bg-white p-1 rounded-md">
              <Link to="/">
                <img src="/favicon.ico" width="30" height="30" alt="Logo" />
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:block my-auto">
              <div className="flex space-x-3">
                {menuTabs.map((tab, tabIdx) => {
                  if (tab.roles.indexOf(!isEmptyOrUndefined(userObject) ? userObject.role : "NULL") > -1) {
                    return (
                      <Link
                        to={tab.link}
                        key={tabIdx}
                        className="no-underline my-auto"
                      >
                        <span
                          className={
                            pathname === tab.link
                              ? "bg-[#1559ed] text-white rounded-md px-3.5 py-2.5 text-sm font-medium cursor-pointer"
                              : "bg-white text-[#666] rounded-md px-3.5 py-2.5 text-sm font-medium cursor-pointer"
                          }
                          aria-current="page"
                        >
                          {tab.title}
                        </span>
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center p-1">
            <div className="text-base text-black mx-3">Powered by :</div>
            <img src="/logo.svg" width="100" alt="Logo" />
          </div>
          {!isEmptyOrUndefined(userObject) && <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <ProfileButton />
          </div>}
        </div>
      </div>

      <div className="sm:hidden" id="mobile-menu">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {menuTabs.map((tab, tabIdx) => {
            if (tab.roles.indexOf(!isEmptyOrUndefined(userObject) ? userObject.role : "NULL") > -1) {
              return (
                <Link to={tab.link} key={tabIdx} className="no-underline">
                  <span
                    className={
                      pathname === tab.link
                        ? "bg-[#1559ed] block text-white rounded-md px-3.5 py-2.5 text-sm font-medium cursor-pointer "
                        : "bg-white block text-[#666] rounded-md px-3.5 py-2.5 text-sm font-medium cursor-pointer "
                    }
                    aria-current="page"
                  >
                    {tab.title}
                  </span>
                </Link>
              );
            }
          })}
        </div>
      </div>
    </nav>
  );
};

export default NavMenu;
