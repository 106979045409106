import React from "react";
import "./TextPreview.scss";

const TextPreview = (props: { htmlContent: string; className?: string }) => {
  return (
    <div
      className={"flex flex-col container " + props.className || ""}
      dangerouslySetInnerHTML={{
        __html: props.htmlContent,
      }}
    />
  );
};

export default TextPreview;
