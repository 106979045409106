import React from "react";
import EditorPopup from "../EditorPopup";
import TextPreview from "../TextPreview/TextPreview";
import { useAnswerObject } from "../../../hooks";

const SubjectiveFormat = (props: { mode: "Edit" | "Preview" | "Solve" | "SolutionCheck" }) => {
  const [answerObj, setanswerObj] = useAnswerObject() as any;
  return (
    <div className="flex">
      {props.mode === "Edit" ? (
        <EditorPopup
          saveHtmlText={(value: string) => {
            setanswerObj({ content: value });
          }}
          defautValue={answerObj.content}
        />
      ) : props.mode === "Preview" ? (
        <TextPreview htmlContent={answerObj.content} />
      ) : (
        "TODO" // To be updated later. 
      )}
    </div>
  );
};

export default SubjectiveFormat;
