import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import HomeContent from "./HomeContent.tsx";
import AssignmentsContent from "./assignments/AssignmentsContent";
import QuestionsContent from "./questions/QuestionsContent";
import TestContent from "./test/TestContent";
import HomeDashboard from "./dashboard/HomeDashboard";
import AccountDashboard from "./dashboard/AccountDashboard"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeContent />,
    children: [
      {
        path:"/",
        element: <HomeDashboard/>,
      },
      {
        path: "/assignments",
        element: <AssignmentsContent />,
      },
      {
        path: "/challenges",
        element: <AssignmentsContent />, // Note: To be updated later.
      },
      {
        path: "/account",
        element: <AccountDashboard/>
      },
      {
        path: "/questions",
        element: <QuestionsContent />,
      },
      { path: "/test/:id", element: <TestContent /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
