import React, { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import EditorPopup from "../EditorPopup";
import TextPreview from "../TextPreview/TextPreview";
import { useAnswerObject } from "../../../hooks";
import { isEmptyOrUndefined } from "../../../util";

const EmptyCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

type CQOptionValueType = {
  value?: string;
  isSelected?: boolean;
};

type CQFormatValueType = {
  A?: CQOptionValueType;
  B?: CQOptionValueType;
  C?: CQOptionValueType;
  D?: CQOptionValueType;
};

const CQOption = (props: {
  mode: "Edit" | "Preview" | "Solve" | "SolutionCheck";
  id: string;
}) => {
  const [answerObj, setanswerObj] = useAnswerObject() as any;
  const [value, setvalue] = useState<CQOptionValueType>({});
  const updateAnswer = (res: CQOptionValueType) => {
    const tmp = Object.assign({}, answerObj);
    tmp[props.id as keyof CQFormatValueType] = res;
    setanswerObj(tmp);
  };

  useEffect(() => {
    if (!isEmptyOrUndefined(answerObj)) {
      setvalue(
        (answerObj[props.id as keyof CQFormatValueType] as CQOptionValueType) ||
          {}
      );
    } else {
      setvalue({});
    }
  }, [answerObj]);

  return (
    <div className="flex min-h-[100px] overflow-auto w-full bg-white border p-2 rounded-md shadow-md">
      <div className="flex mx-2 uppercase my-auto font-bold">{props.id}.</div>
      <div
        className="flex my-auto cursor-pointer w-6 h-6 me-6 text-yellow-500"
        onClick={() => {
          if (props.mode !== "Preview" && props.mode !== "SolutionCheck") {
            updateAnswer({ ...value, isSelected: !value.isSelected });
          }
        }}
      >
        {value.isSelected ? <CheckCircleIcon className={props.mode === "SolutionCheck" ? "text-green-500" : ''} /> : <EmptyCircle />}
      </div>

      {props.mode === "Edit" ? (
        <EditorPopup
          defautValue={value.value || ""}
          saveHtmlText={(res: string) => {
            updateAnswer({ ...value, value: res });
          }}
        />
      ) : (
        <TextPreview htmlContent={value.value || ""} />
      )}
    </div>
  );
};

const CQFormat = (props: { mode: "Edit" | "Preview" | "Solve" | "SolutionCheck" }) => {
  return (
    <div className="flex flex-col w-full overflow-auto">
      <div className="flex w-full justify-start my-2 space-x-6">
        <CQOption mode={props.mode} id="A" />
        <CQOption mode={props.mode} id="B" />
      </div>
      <div className="flex w-full justify-start my-2 space-x-6">
        <CQOption mode={props.mode} id="C" />
        <CQOption mode={props.mode} id="D" />
      </div>
    </div>
  );
};

export default CQFormat;
