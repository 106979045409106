import React from "react";

const GenericButton = (props: {
  variant: "primary" | "secondary" | "tertiary" | "quaternary";
  size: "sm" | "md";
  className?: string;
  children?: any;
  onClick?: any;
}) => {
  const sizeClass = {
    sm: "h-auto p-1 rounded-md",
    md: "h-auto p-2 rounded-md",
  };
  const variantClass = {
    primary: "text-white bg-yellow-500 hover:opacity-60",
    secondary:
      "text-yellow-500 bg-slate-50 ring ring-yellow-500 hover:opacity-60",
    tertiary: "text-white bg-zinc-600 hover:opacity-60",
    quaternary: "text-white bg-[#3A4F66] hover:opacity-60"
  };
  const className =
    sizeClass[props.size] +
    " cursor-pointer text-center justify-items-center my-auto " +
    variantClass[props.variant] +
    " " +
    props.className;
  return (
    <div className={className} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default GenericButton;
