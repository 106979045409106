import React from "react";

const QuestionProgressStepper = (props: {
  currentQuestion: number;
  numQuestion: number;
  onQuesClick: (quesNumber: number) => void;
}) => {
  const getLiClassnames = (index: number) => {
    let cls = "flex w-full items-center text-gray-100 ";
    if (index < props.currentQuestion) {
      cls =
        cls +
        "text-yellow-600 dark:text-yellow-100 after:border-yellow-100 dark:after:border-yellow-500 ";
    } else if (index === props.currentQuestion) {
      cls = cls + "";
    }

    if (index === props.numQuestion) {
      cls = cls + "";
    } else {
      cls =
        cls +
        "after:content-[''] after:w-full after:h-1 after:border-b after:border-4 after:inline-block ";
    }

    return cls;
  };

  const getSpanClassnames = (index: number) => {
    let cls = "flex items-center justify-center p-3 rounded-full shrink-0 ";

    if (index <= props.currentQuestion) {
      cls = cls + "bg-yellow-100 dark:bg-yellow-500";
      if (index === props.currentQuestion) {
        cls = cls + " font-semibold text-yellow-900";
      }
    } else {
      cls = cls + " bg-gray-300 dark:bg-gray-300 ";
    }
    return cls;
  };

  const getItems = () => {
    let items = [];
    for (let index = 1; index <= props.numQuestion; index++) {
      items.push(
        <button onClick={() => { props.onQuesClick(index) }}>
          <li key={index} className={getLiClassnames(index)}>
            <span className={getSpanClassnames(index)}>Q {index}</span>
          </li>
        </button>

      );
    }
    return items;
  };

  return <ol className="flex items-center w-full">{getItems()}</ol>;
};

export default QuestionProgressStepper;
