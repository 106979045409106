import React from "react";
import { ToastContainer } from "react-toastify";
import { useUserObject } from "./hooks";
import { isEmptyOrUndefined } from "./util";
import NavMenu from "./NavMenu";
import LoginTabs from "./LoginTabs";
import { Outlet } from "react-router-dom";

const HomeContent = () => {
  const [userObject, setuserObject] = useUserObject() as any;
  return (
    <>
      <ToastContainer />
      {isEmptyOrUndefined(userObject) ? (
        <div className="w-full h-full min-h-screen flex-col bg-slate-200 items-center">
          <div className="w-full"><NavMenu /></div>
          <div className="flex justify-center pt-40"><LoginTabs /></div>
        </div>
      ) : (
        <main className="flex flex-col min-h-screen min-w-full space-y-24 bg-blue-50 items-center">
          <NavMenu />
          <Outlet />
        </main>
      )}
    </>
  );
};

export default HomeContent;
