import React, { useEffect } from "react";
import { useState } from "react";
import EditorPopup from "./EditorPopup";
import GenericButton from "../GenericButton";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import GenericDropdown from "../GenericDropdown";
import AnswerFormat from "./AnswerFormat/AnswerFormat";
import TextPreview from "./TextPreview/TextPreview";
import { useAnswerObject, useErrorsObject } from "../../hooks";
import { isEmptyHTMLString, isEmptyOrUndefined } from "../../util";
import QuestionSolutionTab from "./QuestionSolutionTab";

export default function QuestionView(props: {
  data: any;
  writeQuestionToDb: (Q: any) => void;
  editMode: boolean;
  showSolution: boolean;
}) {
  const [answerObject, setanswerObject] = useAnswerObject() as any;
  const [errorsObject, seterrorsObject] = useErrorsObject() as any;
  const [question, setquestion] = useState(props.data?.question || "");
  const [solution, setsolution] = useState(props.data?.solution || "");
  const [questionType, setquestionType] = useState(
    props.data?.questionType || "Choice Question"
  );
  const [subject, setsubject] = useState(props.data?.subject || "Physics");
  const [class_, setclass_] = useState(props.data?.class || "Class 12");

  useEffect(() => {
    setanswerObject(props.data?.answerObject);
  }, []);

  const validateAndSubmit = () => {
    const validateData = () => {
      let goodToGo = true;
      if (isEmptyOrUndefined(question) || isEmptyHTMLString(question)) {
        goodToGo = false;
        seterrorsObject({ ...errorsObject, question: true });
      }
      if (questionType === "Choice Question") {
        ["A", "B", "C", "D"].map((id) => {
          if (
            isEmptyOrUndefined(answerObject) ||
            isEmptyOrUndefined(answerObject[id]) ||
            isEmptyOrUndefined(answerObject[id].value) ||
            isEmptyHTMLString(answerObject[id].value)
          ) {
            goodToGo = false;
            const tmp = Object.assign({}, errorsObject);
            if (!tmp.answer) {
              tmp.answer = {};
            }
            tmp.answer[id] = true;
            seterrorsObject(tmp);
          }
        });
      } else {
        if (
          isEmptyOrUndefined(answerObject) ||
          isEmptyOrUndefined(answerObject.content) ||
          isEmptyHTMLString(answerObject.content)
        ) {
          goodToGo = false;
          seterrorsObject({ ...errorsObject, answer: true });
        }
      }
      if (isEmptyOrUndefined(solution) || isEmptyHTMLString(solution)) {
        goodToGo = false;
        seterrorsObject({ ...errorsObject, solution: true });
      }
      return goodToGo;
    };

    // if (validateData()) {
    props.writeQuestionToDb({
      class: class_,
      subject,
      questionType,
      question,
      answerObject,
      solution,
    });
    clearData();
    // } else {
    //   toast.error("One or more fields empty");
    // }
  };

  const clearData = () => {
    setquestion("");
    setsolution("");
    setanswerObject({});
  };

  return (
    <main className="flex w-full h-full flex-col">
      <div className="flex w-full h-full divide-x-4 divide-yellow-100">
        {props.editMode && (
          <div className="flex flex-col w-full h-full bg-yellow-100 rounded-lg shadow-sm p-4">
            <div className="flex space-x-4">
              <GenericDropdown
                options={["Choice Question", "Subjective"]}
                updateValue={(value) => {
                  setanswerObject({});
                  setquestionType(value);
                }}
                defaultValue={questionType}
              />
              <GenericDropdown
                options={[
                  "Class 8",
                  "Class 9",
                  "Class 10",
                  "Class 11",
                  "Class 12",
                ]}
                updateValue={setclass_}
                defaultValue={class_}
              />
              <GenericDropdown
                options={["Physics", "Chemistry", "Mathematics"]}
                updateValue={setsubject}
                defaultValue={subject}
              />
              <GenericDropdown
                options={["Topic"]}
                updateValue={() => {}}
                defaultValue="Topic"
              />
            </div>

            <div className="flex flex-col mt-6">
              <QuestionSolutionTab
                question={
                  <>
                    <div className="font-bold text-xl mb-2 text-yellow-500">
                      Question
                    </div>
                    <div className="flex">
                      <EditorPopup
                        saveHtmlText={setquestion}
                        defautValue={question}
                      />
                    </div>
                    <div className="font-bold text-xl my-4 text-yellow-500">
                      Answer
                    </div>

                    <AnswerFormat mode="Edit" questionType={questionType} />
                  </>
                }
                solution={
                  <>
                    <div className="font-bold text-xl my-4 text-yellow-500">
                      Solution
                    </div>
                    <div className="flex">
                      <EditorPopup
                        defautValue={solution}
                        saveHtmlText={setsolution}
                      />
                    </div>
                  </>
                }
                showSolution={props.showSolution}
              />
            </div>
          </div>
        )}

        <div className="flex flex-col w-full h-full overflow-auto m-2 p-4">
          <QuestionSolutionTab
            question={
              <>
                <TextPreview
                  htmlContent={question + "<br/><br/>"}
                  className="min-h-[200px]"
                />
                <AnswerFormat
                  mode={props.editMode ? "Preview" : props.showSolution ? "SolutionCheck" : "Solve"}
                  questionType={questionType}
                />
              </>
            }
            solution={
              <>
                <TextPreview htmlContent={solution} />
              </>
            }
            showSolution={props.showSolution}
          />
        </div>
      </div>

      {props.editMode && (
        <div className="flex justify-end align-bottom m-2 mt-8">
          <GenericButton
            variant="primary"
            size="sm"
            className="bg-green-600"
            onClick={validateAndSubmit}
          >
            <div className="flex">
              <div className="flex w-4 me-1">
                <CheckBadgeIcon />
              </div>
              Save Question
            </div>
          </GenericButton>
        </div>
      )}
    </main>
  );
}
