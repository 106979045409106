import { isEmptyOrUndefined } from "../util";
import React, { useEffect, useState } from "react";
import Instruction from "./Instruction";
import Test from "./Test";
import { atom, useAtom } from "jotai";
import { useDatabase, useUserObject } from "../hooks";
import { onValue, ref } from "firebase/database";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router-dom";
import AssignmentIcon from "../assignments/AssignmentIcon";

const TestContent = () => {
  const database = useDatabase();
  const { id } = useParams();
  const assignmentRef = ref(database, "assignments/" + id);
  const [assignment, setassignment] = useState<any>();
  const [userAssignmentDetails, setUserAssignmentDetails] = useState<any>({ currQ: 1 , testStatus: "STARTING", score: 0, startTime: new Date(Date.now()).toUTCString()});
  const handle = useFullScreenHandle();

  useEffect(() => {
    onValue(assignmentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setassignment(data);
      }
    });
  }, []);

  const [userObject, setuserObject] = useUserObject() as any;
  const userAssignmentRef = ref(
    database,
    "users/" + userObject.uid + "/assignments/" + id
  );

  useEffect(() => {
    onValue(userAssignmentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setUserAssignmentDetails(data);
      }
    });
  }, []);

  const startTest = () => {
    setUserAssignmentDetails({ ...userAssignmentDetails, testStatus: "RUNNING", startTime: new Date(Date.now()).toUTCString()})
  };

  return (
    <div className="flex flex-col w-full h-full transition-transform transform">
      <div className="flex text-[#121619] place-self-center mr-10 space-x-2 font-serif my-12">
        <div className="flex h-10">
          <AssignmentIcon />
        </div>
        <span className="whitespace-nowrap tracking-wide text-2xl/10">
          {assignment?.name + " - " + assignment?.subject}
        </span>
      </div>
      <div className="flex bg-slate-100 rounded-3xl border-2 border-[#3A4F66] h-full mx-32 my-auto shadow-sm">
        <FullScreen handle={handle} className="w-full">
          {!assignment ? (
            ""
          ) : userAssignmentDetails.testStatus === "STARTING" ? (
            <Instruction startTest={startTest} assignment={assignment} />
          ) : (
            <Test
              assignment={assignment}
              assignmentId={id || ""}
              fullScreenHandle={handle}
              userAssignmentDetails={userAssignmentDetails}
              userAssignmentRef={userAssignmentRef}
            />
          )}
        </FullScreen>
      </div>
      <div className="h-[150px]"></div>
    </div>
  );
};

export default TestContent;
