export const isEmptyOrUndefined = (v: any) => {
  if (!v || v === "" || JSON.stringify(v) === "{}") {
    return true;
  } else {
    return false;
  }
};

export const isEmptyHTMLString = (s: string) => {
  if (typeof s !== "string") {
    return false;
  }
  var regex = /(<([^>]+)>)/gi;
  return !s.replace(regex, "").length;
};

export function firstLetterCapitalize(str: string){
return str.charAt(0).toUpperCase() + str.slice(1);  
}

export function matchAnswers(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  for (const key of keys2) {

    const userKeys = Object.keys(obj2[key]);
    const hasUserEnteredOption: boolean = (userKeys.includes("isSelected") && obj2[key]["isSelected"] === true)

    if (!keys1.includes(key)) {
      if (hasUserEnteredOption === true) return false;
    }
    else {
      const answerKeys = Object.keys(obj1[key]);
      const isAnswerOptionTrue: boolean = (answerKeys.includes("isSelected") && obj1[key]["isSelected"] === true)

      if (isAnswerOptionTrue !== hasUserEnteredOption) return false;
    }
  }

  return true;
}